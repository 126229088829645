<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>入库记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->

    <el-card class="box-card">
      <div class="top">
        <div class="lets">
          <div class="tops">
            <div class="title">货物编号 :</div>
            <el-autocomplete
              clearable
              :trigger-on-focus="false"
              placeholder="请输入货物编号"
              v-model="shaibian"
              :fetch-suggestions="bianvalidateCounts"
              value-key="goods_no"
              @select="bianhandleSelect($event, '货物编号')"
              @clear="bianqing"
            ></el-autocomplete>
          </div>
          <div class="tops">
            <div class="title">货物名称 :</div>
            <el-autocomplete
              clearable
              :trigger-on-focus="false"
              placeholder="请输入货物名称"
              v-model="shainame"
              :fetch-suggestions="huowuvalidateCounts"
              value-key="name"
              @select="handleSelect($event, '货物名称')"
              @clear="huoqing"
            ></el-autocomplete>
          </div>
          <div class="tops">
            <div class="title">仓库名称 :</div>
            <el-autocomplete
              clearable
              :trigger-on-focus="false"
              placeholder="请输入仓库关键字"
              v-model="house"
              :fetch-suggestions="cangvalidateCounts"
              value-key="name"
              @select="canghandleSelect($event)"
              @clear="cangqing"
            ></el-autocomplete>
          </div>
        </div>
        <div class="rigs lets">
          <!-- <div class="tops">
            <div class="title">验收人 :</div>
            <el-autocomplete
              clearable
              :trigger-on-focus="false"
              placeholder="请输入内容"
              v-model="yanuser"
              :fetch-suggestions="yanvalidateCounts"
              value-key="name"
              @select="yanhandleSelect($event, '验收人')"
              @clear="yanqing"
            ></el-autocomplete>
          </div> -->
          <div class="tops">
            <div class="title">货物类别 :</div>
            <el-cascader v-model="type" :options="leilist" :show-all-levels="false" @change="leichenge" :props="propss" clearable></el-cascader>
          </div>
          <div class="tops">
            <div class="title">日期筛选 :</div>
            <el-date-picker
              @change="gettime"
              v-model="timeFrom"
              type="daterange"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @clear="riqing"
            ></el-date-picker>
          </div>
          <div class="tops">
            <el-button type="primary" @click="chong" class="btn">重新选择</el-button>
          </div>
        </div>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="batch_no" label="批次编号" width="200"></el-table-column>
        <el-table-column prop="goods_no" label="货物编号" width="150"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称" width="200"></el-table-column>
        <el-table-column prop="goods_type" label="货物类别" width="200"></el-table-column>
        <el-table-column label="入库类型" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.order_type == 1 ? '入库' : '出库' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_time" label="入库时间" width="150"></el-table-column>
        <el-table-column prop="goods_unit" label="货物单位" align="center" width="100"></el-table-column>
        <el-table-column prop="goods_num" label="入库数量" align="center" width="100"></el-table-column>
        <el-table-column prop="order_username" label="入库人" align="center" width="100"></el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Drop } from '../../../api/slogong'
import { Cangsou, Getpeilist, Shopsou, Huoshu, Houding } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      type: '', // 货物类别
      shaibian: '', // 货物编号
      shainame: '', // 货物筛选名称
      yanid: '', // 验收人id
      storelist: [], // 仓库搜索
      house: '', // 仓库名称
      yanuser: '', // 验收人
      leilist: [], // 货物类型
      yanlist: [], // 验收人列表
      timeFrom: [], // 筛选时间数组
      bianlist: [],
      huolist: [],
      tableData: [], // 列表数据
      from_time: '', // 开始事件
      end_time: '', // 结束事件
      shaitype: '',
      propss: {
        label: 'title',
        children: 'list',
        value: 'id'
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Huowulei()
    this.Houdinglist()
  },
  methods: {
    // 重新选择
    chong() {
      this.shaibian = ''
      this.shainame = ''
      this.shaitype = ''
      this.house = ''
      this.timeFrom = []
      this.from_time = ''
      this.end_time = ''
      this.yanuser = ''
      this.yanid = ''
      this.Houdinglist()
    },
    // 仓库搜索清空
    cangqing() {
      this.house = ''
      this.Houdinglist()
    },

    // 编号搜索清空
    bianqing() {
      this.shaibian = ''
      this.Houdinglist()
    },

    // 日期搜索清空
    riqing() {
      console.log('日期搜索清空')
    },

    // 货物清空
    huoqing() {
      this.shainame = ''
      this.Houdinglist()
    },

    // 验收人请空
    yanqing() {
      console.log('验收人清空')
    },

    // 类别选中
    leichenge(e) {
      // console.log(e)
      this.shaitype = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      this.Houdinglist()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Houdinglist()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Houdinglist()
    },

    // 货物编号
    bianvalidateCounts(queryString, cb) {
      this.Brops(queryString, cb)
    },

    // 货物编号选中
    bianhandleSelect(e) {
      // console.log(e)
      this.Houdinglist()
    },
    // 搜商品的数据
    huowuvalidateCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },
    // 货品搜索选中
    handleSelect(e) {
      // console.log(e)
      this.Houdinglist()
    },
    // 日期选中事件

    // 日期选中事件
    gettime(timeFrom) {
      if (timeFrom !== null) {
        this.from_time = timeFrom ? timeFrom[0] : ''
        this.end_time = timeFrom ? timeFrom[1] : ''
        this.Houdinglist()
      } else {
        this.from_time = ''
        this.end_time = ''
        this.Houdinglist()
      }
    },

    // 搜索仓库
    cangvalidateCounts(queryString, cb) {
      this.Cang(queryString, cb)
    },
    // 搜索仓库选中
    canghandleSelect(e) {
      // console.log(e)
      this.Houdinglist()
    },
    // 搜索验收人后的数据
    yanvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },
    // 选中验收人
    yanhandleSelect(e, val) {
      console.log(e, val)
      this.yanid = e.value
    },

    // 仓库搜索
    async Cang(name, cd) {
      const { data } = await Cangsou({ name })
      this.storelist = data.data
      cd(data.data)
    },
    // 商品搜索
    async Drops(name, cd) {
      const { data } = await Shopsou({ name })
      this.huolist = data.data
      cd(data.data)
    },

    // 验收人
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      this.yanlist = data.data
      cd(data.data)
    },
    // 商品编号搜索
    async Brops(goods_no, cd) {
      const { data } = await Getpeilist({ goods_no })
      this.bianlist = data.data.data
      cd(data.data.data)
    },
    // 仓库类型
    async Huowulei() {
      const { data } = await Huoshu()
      this.leilist = data.data
    },

    async Houdinglist() {
      const { data } = await Houding({
        order_type: 1,
        ...this.pageData,
        from_time: this.from_time,
        end_time: this.end_time,
        goods_no: this.shaibian,
        goods_name: this.shainame,
        house_name: this.house,
        goods_type: this.shaitype
      })
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    }
  }
}
</script>

<style scoped>
.tops {
  /* margin-left: 30px; */
  display: flex;
  align-items: center;
}
.top {
  display: flex;
  align-items: center;
}
.let {
  width: 50px;
  margin-left: 15px;
}
.tan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lets {
  width: 500px;
}
.title {
  width: 80px;
}
.btn {
  margin-left: 100px;
}
</style>